import { FallbackStrategy, SiteAssetsRequest, SitePagesModel } from 'site-assets-client'
import { TBSiteAssetsRequest } from './types'
import { stringifyExperiments } from './utils'
import {
	CssSiteAssetsParams,
	ModulesToHashes,
	PlatformSiteAssetsParams,
	PropsSiteAssetsParams,
	SiteAssetsResourceType,
	StructureSiteAssetsParams,
	ViewerModel,
} from '@wix/thunderbolt-symbols'

type OneOfSiteAssetsParams =
	| CssSiteAssetsParams
	| PlatformSiteAssetsParams
	| PropsSiteAssetsParams
	| StructureSiteAssetsParams

type SiteAssetsParamsMap<U> = { [K in SiteAssetsResourceType]: U extends { resourceType: K } ? U : never }
type SiteAssetsParamsTypeMap = SiteAssetsParamsMap<OneOfSiteAssetsParams>
type Pattern<T> = { [K in keyof SiteAssetsParamsTypeMap]: (params: SiteAssetsParamsTypeMap[K]) => T }
function matcher<T>(pattern: Pattern<T>): (params: OneOfSiteAssetsParams) => T {
	// https://github.com/Microsoft/TypeScript/issues/14107
	return (params) => pattern[params.resourceType](params as any)
}

export const getUniqueParamsPerModule = ({
	deviceInfo,
	staticHTMLComponentUrl,
	qaMode,
}: {
	deviceInfo: ViewerModel['deviceInfo']
	staticHTMLComponentUrl: string
	qaMode?: ViewerModel['mode']['qa']
}) => {
	return matcher<Record<string, string>>({
		css: (cssParams) => {
			return {
				language: cssParams.language,
				stylableMetaData: cssParams.stylableMetaData,
			}
		},
		props: (propsParams) => {
			return {
				language: propsParams.language,
				useSandboxInHTMLComp: `${propsParams.useSandboxInHTMLComp}`,
				deviceType: deviceInfo.deviceClass,
				osType: deviceInfo.os,
				staticHTMLComponentUrl,
				...(qaMode && { qaMode: 'true' }),
			}
		},
		structure: () => {
			return {
				...(qaMode && { qaMode }),
			}
		},
		platform: (platformParams) => {
			return {
				language: platformParams.language,
			}
		},
	})
}

export const getParamsFromRequest = ({
	siteScopeParams: { freemiumBanner, viewMode, isWixSite, isResponsive, wixCodePageIds },
	errorPageId,
	beckyExperiments,
	pageCompId,
}: TBSiteAssetsRequest) => {
	const params = {
		freemiumBanner: freemiumBanner ? `${freemiumBanner}` : undefined,
		isWixCodeOnPage: wixCodePageIds ? `${wixCodePageIds.includes(pageCompId)}` : undefined,
		viewMode: viewMode || undefined,
		isWixSite: isWixSite ? `${isWixSite}` : undefined,
		errorPageId: errorPageId || undefined,
		isResponsive: isResponsive ? `${isResponsive}` : undefined,
		beckyExperiments: stringifyExperiments(beckyExperiments) || undefined,
	}

	return Object.entries(params).reduce(
		(returnValue, [key, value]) => (value ? { ...returnValue, [key]: value } : returnValue),
		{}
	)
}

export function toSiteAssetsRequest(
	request: TBSiteAssetsRequest,
	modulesToHashes: ModulesToHashes,
	pageJsonFileNames: SitePagesModel['pageJsonFileNames'],
	fallbackStrategy: FallbackStrategy
) {
	const { moduleParams, pageCompId, deviceInfo, staticHTMLComponentUrl, qaMode, pageJsonFileName } = request
	const { contentType, moduleName } = moduleParams

	const siteAssetsRequest: SiteAssetsRequest = {
		endpoint: {
			controller: 'pages',
			methodName: 'thunderbolt',
		},
		module: {
			name: moduleName,
			version: modulesToHashes[moduleName],
			fetchType: 'file',
			params: {
				...getParamsFromRequest(request),
				...getUniqueParamsPerModule({
					deviceInfo,
					staticHTMLComponentUrl,
					qaMode,
				})(moduleParams),
			},
		},
		contentType,
		fallbackStrategy,
		pageJsonFileName: pageJsonFileName || pageJsonFileNames[pageCompId],
	}

	return siteAssetsRequest
}
