import { SiteAssetsClientAdapterFactoryImpl } from './siteAssetsClientAdapter'
import {
	DataFixersParams,
	FetchFn,
	SiteAssetsClientTopology,
	SiteAssetsManifests,
	SiteScopeParams,
} from '@wix/thunderbolt-symbols'
import { SiteAssetsMetricsReporter, SiteAssetsModuleFetcher, SiteAssetsTopology } from 'site-assets-client'
import { SiteAssetsClientAdapter } from './types'

type ClientSiteAssetsClientAdapterFactoryParams = {
	fetchFn: FetchFn
	clientTopology: SiteAssetsClientTopology
	siteAssetsMetricsReporter: SiteAssetsMetricsReporter
	manifests: SiteAssetsManifests
	timeout: number
	dataFixersParams: DataFixersParams
	requestUrl: string
	siteScopeParams: SiteScopeParams
	moduleFetcher: SiteAssetsModuleFetcher
}

const toSiteAssetsTopology = (clientTopology: SiteAssetsClientTopology): SiteAssetsTopology => {
	const {
		mediaRootUrl,
		staticMediaUrl,
		htmlComponentsDomainUrl,
		siteAssetsUrl,
		moduleRepoUrl,
		fileRepoUrl,
	} = clientTopology

	return {
		mediaRootUrl,
		staticMediaUrl,
		htmlComponentsDomainUrl,
		siteAssetsServerUrl: siteAssetsUrl,
		moduleRepoUrl,
		fileRepoUrl,
	}
}

export const createClientSiteAssetsClientFactory = ({
	fetchFn,
	clientTopology,
	siteAssetsMetricsReporter,
	manifests,
	timeout,
	dataFixersParams,
	requestUrl,
	siteScopeParams,
	moduleFetcher,
}: ClientSiteAssetsClientAdapterFactoryParams): SiteAssetsClientAdapter => {
	const topology = toSiteAssetsTopology(clientTopology)
	const config = {
		moduleTopology: {
			publicEnvironment: topology,
			environment: topology,
		},
		staticsTopology: {
			timeout,
			baseURLs: clientTopology.pageJsonServerUrls,
		},
	}

	return SiteAssetsClientAdapterFactoryImpl({
		fetchFn,
		config,
		siteAssetsMetricsReporter,
		manifests,
		moduleFetcher,
	})({
		dataFixersParams,
		requestUrl,
		siteScopeParams,
	})
}
