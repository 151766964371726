import _ from 'lodash'
import { $W, SdkInstance, Connection, PlatformLogger } from '@wix/thunderbolt-symbols'
import { ComponentSdks, ComponentSdksLoader, CoreSdkLoaders } from '../types'
import ComponentBaseSDK from './componentsSDK/ComponentBaseSDK'
import { Models } from './model'
import { SdkFactoryParams } from './createSdkFactoryParams'
import { IControllerEvents } from './ControllerEvents'

export interface WixSelector {
	create$w: (controllerCompId: string) => $W
	getInstance: any
	$wFactory: (getInstancesForRole: (role: string, findOnlyNestedComponents: boolean) => Array<SdkInstance>, controllerId: string) => $W
	instanceCache: Record<string, any>
	flushOnReadyCallbacks: () => Promise<any>
	fetchComponentsSdks: (coreSdksLoaders: CoreSdkLoaders) => void
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Copying_accessors
function assignWithGettersAndSetters(target: any, source: any) {
	Object.defineProperties(target, _.fromPairs(Object.keys(source).map((key) => [key, Object.getOwnPropertyDescriptor(source, key)!])))
}

export default function({
	models,
	loadComponentSdksPromise,
	logger,
	createSdkHandlers,
	getSdkFactoryParams,
	controllerEventsFactory
}: {
	models: Models
	loadComponentSdksPromise: Promise<ComponentSdksLoader>
	logger: PlatformLogger
	createSdkHandlers: (pageId: string) => any
	getSdkFactoryParams: SdkFactoryParams['getSdkFactoryParams']
	controllerEventsFactory: IControllerEvents
}) {
	const instanceCache: Record<string, any> = {}
	const onReadyCallbacks: { [controllerCompId: string]: Array<Function> } = {}

	const componentsSdks: ComponentSdks = {}
	let sdkResolver = _.noop
	const sdkPromise = new Promise((resolve) => (sdkResolver = resolve))

	async function loadCoreComponentSdks(compTypes: Array<string>, coreSdksLoaders: CoreSdkLoaders) {
		const compsPromises = [...compTypes, 'Document'].filter((type) => coreSdksLoaders[type]).map((type) => coreSdksLoaders[type]().then((sdkFactory) => ({ [type]: sdkFactory })))
		const sdksArray = await Promise.all(compsPromises)
		return Object.assign({}, ...sdksArray)
	}
	async function fetchComponentsSdks(coreSdksLoaders: CoreSdkLoaders) {
		const compTypes = _(Object.values(models.structureModel))
			.map('componentType')
			.uniq()
			.value()
		logger.interactionStarted('loadComponentSdk')
		const { loadComponentSdks } = await loadComponentSdksPromise
		const [coreSdks, sdks] = await Promise.all([loadCoreComponentSdks(compTypes, coreSdksLoaders), loadComponentSdks(compTypes)]).catch((e) => {
			console.error('Error loading componsnts SDK: ', e)
			return {}
		})
		Object.assign(componentsSdks, sdks, coreSdks)
		sdkResolver()
		logger.interactionEnded('loadComponentSdk')
	}

	const invokeControllerOnReady = (controllerCompId: string) => {
		// It's possible to have a controller without an onReady Callback, for example wix code without any $w.onReady().
		if (!onReadyCallbacks[controllerCompId]) {
			return Promise.resolve()
		}

		return onReadyCallbacks[controllerCompId].map((onReady) => onReady())
	}

	const flushOnReadyCallbacks = async () => {
		await sdkPromise
		return Promise.all(_.flatMap(models.platformModel.orderedControllers, invokeControllerOnReady))
	}

	const getChildrenFn = (compId: string, controllerCompId: string) => {
		const compIdConnections = models.platformModel.compIdConnections
		const containersChildrenIds = models.platformModel.containersChildrenIds
		return () => {
			const childrenIds = containersChildrenIds[compId] || []
			return _.map(childrenIds, (id: string) => {
				const connection = _.get(compIdConnections, [id, controllerCompId])

				return getInstance({
					controllerCompId,
					compId: id,
					compType: models.structureModel[id].componentType,
					role: _.get(connection, 'role', ''),
					connection
				})
			})
		}
	}

	function getInstance({
		controllerCompId,
		compId,
		compType,
		role,
		connection
	}: {
		controllerCompId: string
		compId: string
		compType: string
		role: string
		connection?: Connection
	}): SdkInstance | Array<SdkInstance> | null {
		const instanceKey = `${controllerCompId}-${compId}-${role}`
		if (instanceCache[instanceKey]) {
			return instanceCache[instanceKey]
		}
		const handlers = createSdkHandlers(models.getPageIdByCompId(compId))

		const instance = ComponentBaseSDK({
			compId,
			compType,
			connection,
			role,
			handlers
		})

		const componentSdkFactory = componentsSdks[compType]
		if (!componentSdkFactory) {
			console.error(`could not find component SDK for ${compType}`)
			return instance
		}
		const sdkFactoryParams = getSdkFactoryParams({
			compId,
			controllerCompId,
			instance,
			getChildren: getChildrenFn(compId, controllerCompId),
			connection,
			compType
		})
		assignWithGettersAndSetters(instance, componentSdkFactory(sdkFactoryParams))

		instanceCache[instanceKey] = instance
		return instance
	}

	function queueOnReadyCallback(onReadyCallback: () => Promise<any>, controllerId: string) {
		onReadyCallbacks[controllerId] = onReadyCallbacks[controllerId] || []
		onReadyCallbacks[controllerId].push(onReadyCallback)
	}

	function $wFactory(getInstancesForRole: (role: string, findOnlyNestedComponents: boolean) => Array<SdkInstance>, controllerId: string): $W {
		function wixSelectorInternal(selector: string, findOnlyNestedComponents: boolean = false) {
			if (selector === 'Document') {
				// @ts-ignore
				return componentsSdks.Document(controllerId)
			}
			const role = selector.slice(1)
			const instances = getInstancesForRole(role, findOnlyNestedComponents)
			if (!instances.length) {
				return []
			}
			if (_.first(selector) === '#') {
				return instances[0]
			}
			if (instances.length === 1) {
				assignWithGettersAndSetters(instances, instances[0])
			}
			return instances
		}
		const $w = (selector: string) => wixSelectorInternal(selector)
		const controllerEvents = controllerEventsFactory.createScopedControllerEvents(controllerId)
		$w.fireEvent = controllerEvents.fireEvent
		$w.off = controllerEvents.off
		$w.on = controllerEvents.on
		$w.once = controllerEvents.once
		$w.onReady = (cb: () => Promise<any>) => queueOnReadyCallback(cb, controllerId)
		$w.at = _.noop
		$w.createEvent = _.noop
		$w.onRender = _.noop
		$w.scoped = (selector: string) => wixSelectorInternal(selector, true)

		return $w as $W
	}

	const create$w: WixSelector['create$w'] = (controllerCompId) => {
		const getInstancesForRole = (role: string) => {
			const controllerConnectionsByRole = models.platformModel.connections[controllerCompId] || {} // controller might not have connections
			const connections = controllerConnectionsByRole[role] || []
			return connections.map((connection: Connection) => {
				const compId = connection.compId
				const compType = models.structureModel[compId].componentType
				return getInstance({
					controllerCompId,
					compId,
					connection,
					role,
					compType
				})
			})
		}
		return $wFactory(getInstancesForRole, controllerCompId)
	}

	return {
		create$w,
		getInstance,
		$wFactory,
		instanceCache,
		fetchComponentsSdks,
		flushOnReadyCallbacks
	}
}
