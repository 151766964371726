import _ from 'lodash'
import {
	namespace,
	WixStoresWixCodeSdkFactoryInitialState,
	WixStoresWixCodeSdkHandlers,
	WixStoresWixCodeSdkWixCodeApi,
} from '..'
import { PlatformUtils } from '@wix/thunderbolt-symbols'
// import { productOptionsAvailabilityMapper, productVariantsMapper } from '@wix/wixstores-platform-common/dist/src'
import { CATALOG_API_BASE_URL, STORES_APP_DEF_ID } from './constants'
import { productVariantsParamMapper } from '@wix/wixstores-platform-common/dist/src/productVariants/productVariants.mapper'

/**
 * This is SDK Factory.
 * Expose your Corvid API
 */
export function WixStoresSdkFactory(
	initialState: WixStoresWixCodeSdkFactoryInitialState,
	handlers: WixStoresWixCodeSdkHandlers,
	{ sessionServiceApi }: PlatformUtils
): { [namespace]: WixStoresWixCodeSdkWixCodeApi } {
	const getRequestHeaders = () => ({
		Authorization: sessionServiceApi.getInstance(STORES_APP_DEF_ID),
		Accept: 'application/json',
	})

	async function mapResponse(response: any, mapper: Function) {
		if (response.status !== 200) {
			return Promise.reject(await response.text())
		}
		const result = await response.json()
		return mapper(result)
	}

	return {
		[namespace]: {
			async getProductOptionsAvailability(
				productId: string,
				options: { [key: string]: string } = {}
			): Promise<any> {
				const headers = getRequestHeaders()

				const res = await fetch(`${CATALOG_API_BASE_URL}/products/${productId}/productOptionsAvailability`, {
					method: 'post',
					headers,
					body: JSON.stringify({ options }),
				})
				// return mapResponse(res, productOptionsAvailabilityMapper)
				return mapResponse(res, _.identity)
			},
			async getProductVariants(productId: string, options: { [key: string]: string } = {}): Promise<any> {
				const headers = getRequestHeaders()

				const res = await fetch(`${CATALOG_API_BASE_URL}/products/${productId}/variants/query`, {
					method: 'post',
					headers,
					body: JSON.stringify(productVariantsParamMapper(options)),
				})
				// return mapResponse(res, productVariantsMapper)
				return mapResponse(res, _.identity)
			},
		},
	}
}
