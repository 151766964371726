import _ from 'lodash'
import { ControllerDataItem, AppModule, WixCodeApi, PlatformAPI, PlatformUtils, PlatformLogger } from '@wix/thunderbolt-symbols'
import { BootstrapData } from '../types'
import { ControllersExports } from './types'
import { createAppParams } from './appsAPI/appParams'
import { createControllersParams } from './appsAPI/controllerParams'
import { createPlatformAppServicesApi } from './appsAPI/platformServicesAPI'
import { importAndInitElementorySupport } from './elementorySupport'
import { ClientSpecMapAPI } from './clientSpecMapService'
import { WixCodeViewerAppUtils } from './wixCodeViewerAppUtils'
import { ModuleLoader } from './loadModules'
import { Models } from './model'
import { WixSelector } from './wixSelector'
import { BsiManager } from './bsiManagerModule'
import { CreateSetPropsForOOI } from './setPropsFactory'

declare let self: any

export function Applications({
	wixSelector,
	models,
	clientSpecMapApi,
	bootstrapData,
	importScripts,
	moduleLoader,
	wixCodeViewerAppUtils,
	logger,
	wixCodeApiPromise,
	createSetPropsForOOI,
	waitForUpdatePropsPromises,
	controllersExports,
	createPlatformApiForApp,
	bsiManager,
	platformUtils
}: {
	wixSelector: WixSelector
	models: Models
	clientSpecMapApi: ClientSpecMapAPI
	bootstrapData: BootstrapData
	importScripts: Function
	moduleLoader: ModuleLoader
	wixCodeViewerAppUtils: WixCodeViewerAppUtils
	logger: PlatformLogger
	wixCodeApiPromise: Promise<WixCodeApi>
	createSetPropsForOOI: CreateSetPropsForOOI
	waitForUpdatePropsPromises: () => Promise<any>
	controllersExports: ControllersExports
	createPlatformApiForApp: (applicationId: string, instanceId: string) => PlatformAPI
	bsiManager: BsiManager
	platformUtils: PlatformUtils
}) {
	const {
		wixCodeBootstrapData,
		csrfToken,
		externalBaseUrl,
		platformServicesAPIData,
		experiments,
		visitorId,
		routerReturnedData,
		platformEnvData: { bi: biData },
		disabledPlatformApps
	} = bootstrapData
	const { applications, connections } = models.platformModel
	const isAppRunning = (appDefId: string | undefined) => appDefId && applications[appDefId]
	const isWixCodeRunning = isAppRunning(clientSpecMapApi.getWixCodeAppDefinitionId())
	const isDatabindingRunning = isAppRunning(clientSpecMapApi.getDataBindingAppDefinitionId())

	async function loadControllerModule({ controllerType, applicationId: appDefinitionId }: ControllerDataItem): Promise<Record<string, any>> {
		const controllerScriptUrl = clientSpecMapApi.getControllerScript(appDefinitionId, controllerType)
		const controllerModule = controllerScriptUrl ? await moduleLoader.AMDLoader(controllerScriptUrl, 'controllerScript', { appDefinitionId, controllerType }) : null
		return controllerModule ? { [controllerType]: controllerModule } : {}
	}

	async function startApplications() {
		if (isWixCodeRunning || isDatabindingRunning) {
			await importAndInitElementorySupport({
				importScripts,
				wixCodeBootstrapData,
				wixCodeInstance: platformUtils.sessionServiceApi.getWixCodeInstance(),
				viewMode: 'site',
				externalBaseUrl,
				csrfToken
			})
		}

		const appModules = await Promise.all(
			_.map(applications, async (controllers: Record<string, ControllerDataItem>, appDefinitionId: string) => {
				if (disabledPlatformApps[appDefinitionId]) {
					console.warn(`Application ${appDefinitionId} is disabled via query params.`)
					return { [appDefinitionId]: {} }
				}
				const controllersData = _.values(controllers)
				const isWixCode = appDefinitionId === clientSpecMapApi.getWixCodeAppDefinitionId()
				const viewerScriptUrl = clientSpecMapApi.getViewerScriptUrl(appDefinitionId)
				const appSpecData = clientSpecMapApi.getAppSpecData(appDefinitionId)
				const appModulePromise = moduleLoader.AMDLoader<AppModule>(viewerScriptUrl, 'viewerScript', { appDefinitionId })
				const controllerModulesPromise = Promise.all(_.map(controllersData, loadControllerModule)).then((modulesArray) => Object.assign({}, ...modulesArray))
				const routerConfigMap = _.filter(bootstrapData.platformAPIData.routersConfigMap, { appDefinitionId })
				const appParams = createAppParams({
					appSpecData,
					wixCodeViewerAppUtils,
					routerReturnedData,
					routerConfigMap,
					appInstance: platformUtils.sessionServiceApi.getInstance(appDefinitionId)
				})
				const instanceId = appParams.instanceId
				const platformApi = createPlatformApiForApp(appDefinitionId, instanceId)
				const platformAppServicesApi = createPlatformAppServicesApi({
					biData,
					appDefinitionId,
					instanceId,
					platformServicesAPIData,
					experiments,
					visitorId,
					csrfToken,
					bsiManager
				})
				const wixCodeApi = await wixCodeApiPromise
				platformUtils.wixCodeNamespacesRegistry.registerWixCodeNamespaces(wixCodeApi)
				const controllersParams = createControllersParams(
					createSetPropsForOOI,
					controllersData,
					connections,
					wixSelector,
					appSpecData,
					appParams,
					wixCodeApi,
					platformAppServicesApi,
					platformApi,
					csrfToken
				)
				const appModule = await appModulePromise
				if (!appModule) {
					// error loading app module. errors are reported via moduleLoader.
					return { [appDefinitionId]: {} }
				}

				if (appModule.initAppForPage) {
					await logger.withReportingAndErrorHandling('initAppForPage', () => appModule.initAppForPage!(appParams, platformApi, wixCodeApi, platformAppServicesApi), appDefinitionId)
				}
				if (isWixCode) {
					_.forEach(wixCodeApi, (sdk, namespace) => {
						if (namespace === 'events') {
							// https://github.com/wix-private/js-wixcode-sdk/blob/03a175cc86168107b4904d3950c7f66d6844bc9e/js/modules/targets/wixCode.es6#L181
							// do nothing - namespace events is internal
							return
						}
						// https://github.com/wix-private/js-wixcode-namespaces/blob/acdccaebbc0e64db161b5726fc54d4d91d059646/wixcode-users/src/index.js#L6
						if (namespace === 'user') {
							self['wix-users'] = sdk
							return
						}
						// https://github.com/wix-private/js-wixcode-namespaces/blob/e4f0ff6c0cb82d6d5abfba7aa5f4439078334526/wixcode-events/src/index.ts#L4
						if (namespace === 'wixEvents') {
							self['wix-events'] = sdk
							return
						}
						// https://github.com/wix-private/cloud-runtime/blob/master/packages/bundler/cloud-bundler-core/lib/bundler/client-global-libs.js
						self[`wix-${namespace}`] = sdk
					})
				}
				const controllerModules = await controllerModulesPromise

				const controllerPromises = await logger.withReportingAndErrorHandling('createControllers', () => appModule.createControllers(controllersParams, controllerModules), appDefinitionId)
				if (!controllerPromises) {
					return
				}

				await Promise.all(
					controllerPromises.map(async (controllerPromise, index) => {
						const controllerParams = controllersParams[index]
						const controller = await logger.withReportingAndErrorHandling('await_controller_promise', () => controllerPromise, appDefinitionId, controllerParams.type)
						if (!controller) {
							return
						}

						controllersExports[controllerParams.compId] = controller.exports
						const $w = controllersParams[index].$w

						$w.onReady(() => logger.withReportingAndErrorHandling('onReady', () => Promise.resolve(controller.pageReady($w, wixCodeApi)), appDefinitionId, controllerParams.type))
					})
				)
				return { [appDefinitionId]: appModule }
			})
		)

		platformUtils.appsPublicApisUtils.registerAppsPublicApis(Object.assign({}, ...appModules))

		await wixSelector.flushOnReadyCallbacks()
		await waitForUpdatePropsPromises()
	}

	return {
		startApplications
	}
}
