import {
	namespace,
	RealtimeWixCodeSdkFactoryInitialState,
	RealtimeWixCodeSdkHandlers,
	RealtimeWixCodeSdkWixCodeApi,
} from '..'

/**
 * This is SDK Factory.
 * Expose your Corvid API
 */
export function RealtimeSdkFactory(
	{ someKey }: RealtimeWixCodeSdkFactoryInitialState,
	{ doSomething }: RealtimeWixCodeSdkHandlers
): { [namespace]: RealtimeWixCodeSdkWixCodeApi } {
	return {
		[namespace]: {
			someKey,
			doSomething,
		},
	}
}
